import React, { useState, useEffect } from "react"
import styled from "styled-components"
import "../homepage/layout.css"

import axios from "axios"
import CaseStudiesCards from "./CaseStudiesCards"

import { Section, Content } from "../core/LayoutComponents"
import tara from "./tara.png"
import rebecca from "./rebecca.png"
import alysha from "./alysha2.jpg"

const CaseStudiesGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 2rem;
  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`

const Page = () => {
  const [dataArray, setDataArray] = useState([])

  useEffect(() => {
    // const GATSBY_SPARKLE_API_PATH = process.env.GATSBY_SPARKLE_API_PATH
    // const apiCode = process.env.GATSBY_SPARKLE_API_CODE
    // const api = axios.create({
    // baseURL: "https://sparkleinternalfunctions-development.azurewebsites.net",
    // })

    // api.get(`https://sparkleinternalfunctions-development.azurewebsites.net/api/BlogArticles`)

    axios
      .get(`/data/articles.json`, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        // show all blog posts
        // setDataArray(response.data.data)

        // set data array to the latest 3 blog posts
        setDataArray(response.data.data.slice(0, 3))
      })
  }, [])

  // empty array should make it so that there is no infinite loop?

  const casestudiesposts = [
    {
      url: "https://community.sprkl.es/portal/en/community/topic/getting-to-know-alysha-price-of-the-price-dynamic",
      title: "Getting to Know Alysha Price of The Price Dynamic",
      summary:
        "We Sparkle's emphasis on accessibility and simplicity sets it apart from other systems Alysha has used. The platform's message-based scheduling feature addresses the unique needs of her clientele, making it easier for them to engage with her services.",
      photo: (
        <img style={{ backgroundColor: "transparent" }} src={alysha} alt={""} />
      ),
    },
    {
      url: "https://community.sprkl.es/portal/en/community/topic/say-hello-to-tara-halverson-of-kids-fitness-together",
      title: "Say Hello to Tara Halverson of Kids Fitness Together",
      summary:
        "In the past year, Tara has grown Kids Fitness Together from an idea to a full-scale business. She offers weekly kids yoga, tumbling and fitness classes. She uses We Sparkle to share all her registration links, and social media platforms.",
      photo: (
        <img style={{ backgroundColor: "transparent" }} src={tara} alt={""} />
      ),
    },
    {
      url: "https://community.sprkl.es/portal/en/community/topic/meet-rebecca-foley-of-phoenix-business-systems",
      title: "Meet Rebecca Foley of Phoenix Business Systems",
      summary:
        'Rebecca Foley is a bookkeeper that helps creative entrepreneurs with their business. She uses bookkeeping to “help cool people do cool stuff.” While bookkeeping may not be the FIRST thing people think about when they think "cool things,” we are pretty sure a chat with Rebecca (a pretty cool person herself!) may change that.',
      photo: (
        <img
          style={{ backgroundColor: "transparent" }}
          src={rebecca}
          alt={""}
        />
      ),
    },
  ]
  return (
    <Section>
      <Content>
        <div className="recent">
          <h1
            style={{
              paddingBlock: "1rem",
            }}
          >
            Recent Case Studies
          </h1>
        </div>
        <CaseStudiesGrid>
          <CaseStudiesCards casestudiesposts={casestudiesposts} />
        </CaseStudiesGrid>
      </Content>
    </Section>
  )
}

export default Page
