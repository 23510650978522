import React from "react"
import styled from "styled-components"
import { segmentAction } from "../hooks/useSegmentTrack"
import { ButtonPrimary } from "../homepage/Buttons"
import tara from "./tara.png"
import rebecca from "./rebecca.png"
import alysha from "./alysha2.jpg"

const Container = styled.a`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid white;
  border-radius: 10px;
  padding: 1rem;
  transition: 0.2s;

  p {
    font-size: 0.85rem;
    margin-bottom: 0.5rem;
    line-height: 1.5;
    color: white;
    overflow: hidden;
    max-height: 10rem;
    -webkit-box-orient: vertical;
    display: block;
    display: -webkit-box;
    overflow: hidden !important;
    text-overflow: ellipsis;
    -webkit-line-clamp: 6;

    &.label {
      color: var(--sparkle-red);
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight: bold;
    }
  }
  &:hover {
    transform: translateY(-2%);
  }

  img {
    width: 100%;
    height: 400px;
    object-fit: cover;
  }

  @media screen and (max-width: 600px) {
    margin: 0;

    a {
      margin-top: 1rem;
    }
  }
`

const CaseStudiesCards = ({ casestudiesposts }) => {
  return (
    <>
      {casestudiesposts.map(({ url, title, summary, photo}, i) => {
        return (
          <Container
            href={url}
            target="_blank"
            rel={"noopener noreferrer"}
            onClick={() => {
              segmentAction.track(`Press Item Click`, {
                value: title,
              })
            }}
          >
            <div key={`header-${i}`}>
              <h3
                style={{
                  color: "white",
                  paddingBottom: "0.5rem",
                }}
              >
                {title}
              </h3>
              {photo}
              <p
                style={{
                  marginTop: "0.5rem",
                }}
              >
                {summary}
              </p>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                marginTop: "1rem",
              }}
            >
              <ButtonPrimary
                style={{
                  padding: "1rem",
                  fontSize: "0.85rem",
                  fontWeight: 400,
                }}
              >
                Read More
              </ButtonPrimary>
            </div>
          </Container>
        )
      })}
    </>
  )
}

export default CaseStudiesCards
