import React from "react"
import Seo from "../core/Seo"
import CaseStudiesLayout from "../caseStudies/Page"

const CaseStudiesPage = () => {
  return (
    <>
      <Seo
        title="We Sparkle | Case Studies"
        includeZohoTracking
      />
      <CaseStudiesLayout />
    </>
  )
}

export default CaseStudiesPage